import React from "react";

const LeadersCard = ({ name, number, pump }) => {
  function getFirstCharacter(str) {
    // حذف فضای اضافی در ابتدای و انتهای رشته
    str = str.trim();
    // گرفتن اولین حرف
    const firstCharacter = str.charAt(0);
    return firstCharacter;
  }

  const firstCharacter = getFirstCharacter(name ? name : "");
  return (
    <div className="flex relative justify-start items-center w-full border gap-2 border-[#F4AA3F]/20 rounded-[16px] bg-[#1E1E1E] p-2 mt-1 ">
      <span className="text-[24px]">{number+1}.</span>
      <span className="bg-[#F4AA3F] w-[44px] h-[44px] rounded-full flex justify-center items-center text-[#000]">
        {firstCharacter}
      </span>
      <div className="felx flex-col ">
        <span className="text-[16px] font-semibold">{name}</span>
        <span className="text-[14px] mt-1 text-[#F4AA3F]  flex items-center">
          <img className="mb-1 w-[24px] h-[22px]" src="/img/minipump.png" alt="" />
          <p>{pump} $pumps</p>
        </span>
      </div>
    </div>
  );
};

export default LeadersCard;
