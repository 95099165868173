import React from "react";
import { Link } from "react-router-dom";
import LinkIcon from "../icons/LinkIcon";
import { httpReauest } from "../utils/httpRequest";
import { IoMdDoneAll } from "react-icons/io";
const TaskCard = ({  data, user, setData, setBalance, id }) => {
  async function handleTasks() {
    const response = await httpReauest(
      "POST",
      "/task",
      { userId: user?.user?._id, taskId: data?._id },
      {}
    ).then(async (e) => {
      if (e.status === 201) {
        const getUser = await httpReauest("GET", `/user/${id}`, {}, {}).then(
          (e) => {
            console.log(e);
            setData(e?.data?.data);
            if (e?.data?.data?.user?.premium) {
              setBalance(e?.data?.data?.user?.balance - 3000);
            } else {
              setBalance(e?.data?.data?.user?.balance);
            }
          }
        );
      }
    });
  }
  return (
    <div className="flex relative justify-start items-center w-full border gap-2 border-[#F4AA3F]/20 rounded-[16px] bg-[#1E1E1E] p-2 mt-1 ">
      
      <div className="felx flex-col px-2">
        <span className="text-[16px] font-semibold">{data?.content}</span>
        <span className="text-[14px] mt-1 text-[#FFF]/50 flex items-center">
          <img className="mb-1 w-[24px] h-[22px]" src="/img/minipump.png" alt="" />
          <p>{data?.point} $pumps</p>
        </span>
      </div>
      {data?.done ? (
        <span className="absolute right-3 top-3  bg-[#F4AA3F]   px-2 py-1 rounded-[10px]"><IoMdDoneAll size={20}/></span>
      ) : (
        <Link
          onClick={handleTasks}
          to={data?.url}
          target={"_blank"}
          className="absolute right-3 top-3  bg-[#F4AA3F]  border-[#F4AA3F]/20 px-2 py-1 rounded-[10px]"
        >
          Do
        </Link>
      )}
    </div>
  );
};

export default TaskCard;
