import React from "react";
import GameCard from "../components/GameCard";
import CupIcon from "../icons/CupIcon";

const GamePage = () => {
  return (
    <div className="flex flex-col items-center px-4">
      <h2 className="text-[36px] my-5 mt-8">Pump’s Arena</h2>
      <p className="text-[#FFF]/50 text-[16px] text-center mb-5">
        Enter the Pump’s Arena and test your luck in our thrilling mini-games!
      </p>
      <GameCard name={"Flip Coin"} img={"/img/flip-coin.png"} />
      <GameCard name={"Roll Dice"} img={"/img/roll-dice.png"} />
      <GameCard name={"HighRoll"} img={"/img/roller.png"} />
      <span className="flex mt-8 w-full items-center gap-2">
        <CupIcon/>
        <h2 className="text-[24px] font-semibold">Premium Games</h2>
      </span>
      <div className="grid grid-cols-2 gap-2 mt-5 mb-[100px]"> 
         <img className="h-[100px] rounded-[16px]" src="/img/game10.png" alt="" />
         <img className="h-[100px] rounded-[16px]" src="/img/title.jpg" alt="" />
      </div>
    </div>
  );
};

export default GamePage;
