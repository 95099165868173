import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { userContext } from "../context/userContext";
import { httpReauest } from "../utils/httpRequest";
import { IoCloseOutline } from "react-icons/io5";
import { toast } from "react-toastify";
const Logo = () => {
  const { data, setData, balance, setBalance, id, setId } =
    useContext(userContext);
  const [incrementing, setIncrementing] = useState(0);
  const [isButtonActive, setIsButtonActive] = useState(false);
  const [count, setCount] = useState(0);
  const [isCounting, setIsCounting] = useState(false);
  const [incrementRate, setIncrementRate] = useState(1);
  const [modal, setModal] = useState(0);
  const targetDate = new Date(data?.user?.updatedAt);
  // یک روز بعد از تاریخ هدف
  const countdownDate = new Date(targetDate.getTime() + 24 * 60 * 60 * 1000);
  const [timeLeft, setTimeLeft] = useState(countdownDate - new Date());
  async function sendData() {
    const response = await httpReauest(
      "POST",
      "/user/addbalance",
      { balance: incrementing, telegramId: id },
      {}
    ).then(async (e) => {
      if (e.status === 201) {
        const response = await httpReauest("GET", `/user/${id}`, {}, {}).then(
          (e) => {
            setData(e?.data?.data);
            setBalance(e?.data?.data?.user?.balance);
          }
        );
      }
    });
  }
  useEffect(() => {
    if (data?.user?.can_hold) {
      setCount(60);
    }
  }, []);
  useEffect(() => {
    let timer;

    if (isCounting && count > 0) {
      timer = setInterval(() => {
        setCount((prevCount) => prevCount - 1);
      }, 1000);
    } else if (count === 0) {
      setIsCounting(false); // متوقف کردن شمارش
      setIsButtonActive(false);
      if (incrementing > 0) {
        setModal(1);
      }
    }

    return () => clearInterval(timer); // پاکسازی تایمر
  }, [isCounting, count]);

  useEffect(() => {
    if (count === 0) {
      sendData();
    }
  }, [count]);

  useEffect(() => {
    let incrementTimer;
    let holdDuration = 0;

    if (isButtonActive) {
      incrementTimer = setInterval(() => {
        setIncrementing((prevIncrement) => prevIncrement + incrementRate);
        holdDuration += 1000; // مدت زمانی که دکمه نگه داشته شده

        setIncrementRate(incrementRate + holdDuration / 1000);
      }, 1000);
    }

    return () => clearInterval(incrementTimer);
  }, [isButtonActive, incrementRate]);

  function handletouch() {
    if (count > 0) {
      setIsButtonActive(true);
    } else {
      setModal(2)
    }
    if (!isCounting) {
      setIsCounting(true); // شروع شمارش
    }
  }

  function handleTouchEnd() {
    setIsButtonActive(false);
    setIncrementRate(1);
  }

  useEffect(() => {
    const interval = setInterval(() => {
      setTimeLeft(countdownDate - new Date());
    }, 1000);

    return () => clearInterval(interval);
  }, [countdownDate]);

  const formatTimeLeft = (time) => {
    const seconds = Math.floor((time / 1000) % 60);
    const minutes = Math.floor((time / 1000 / 60) % 60);
    const hours = Math.floor((time / (1000 * 60 * 60)) % 24);
    const days = Math.floor(time / (1000 * 60 * 60 * 24));

    return `${hours} : ${minutes} : ${seconds} `;
  };

  return (
    <div className="w-full flex flex-col  items-center mt-5">
      <span style={{ pointerEvents: "none" }} className="text-[24px]">
        {isCounting ? incrementing : "Hold to Earn!"}
      </span>
      
      <button onTouchStart={handletouch} onTouchEnd={handleTouchEnd}>
        <img
          style={{ pointerEvents: "none" }}
          className={`w-[145px] mt-5 img-shadow duration-200 ${
            isButtonActive && "scale-95"
          }`}
          src="/img/Logo.png"
          alt=""
        />
      </button>
      <div
        style={{ pointerEvents: "none" }}
        className="flex w-full justify-between items-center mt-5 gap-2"
      >
        <span className="w-[80%] relative h-[15px] border rounded-2xl border-[#FFA017]/20">
          <span
            style={{ width: count * 1.66 + "%" }}
            className=" absolute left-[1px] rounded-2xl duration-100 right-[1px] top-[1px] bottom-[1px] bg-[#FFA017] "
          ></span>
        </span>
        <span>00:{count}</span>
      </div>
      {modal === 1 && (
        <div className="absolute top-[45%] gap-1 flex flex-col items-center border border-[#FFA017]/20 bg-[#232323] left-10 right-10 rounded-2xl py-6 ">
          <h3 className="text-center text-[20px]">Congratulations!</h3>

          <p>you have received</p>
          <p className="text-[#FFA017]"> {incrementing}$pump</p>
          <button
            onClick={() => {
              setModal(0);
              setIncrementing(0);
            }}
            className="bg-[#FFA017] px-10 py-1 rounded-xl mt-2 text-[#000]"
          >
            OK
          </button>
        </div>
      )}
      {modal === 2 && (
        <div className="absolute top-[45%] gap-1 flex flex-col items-center border border-[#FFA017]/20 bg-[#232323] left-10 right-10 rounded-2xl py-6 ">
          <h3 className="text-center text-[18px]">You can only do it once a day!</h3>

          <p>Remaining time</p>
          <p>{timeLeft > 0 ? formatTimeLeft(timeLeft) : "time is END!"}</p>
          <button
            onClick={() => {
              setModal(0);
              
            }}
            className="bg-[#FFA017] px-10 py-1 rounded-xl mt-2 text-[#000]"
          >
            OK
          </button>
        </div>
      )}
    </div>
  );
};

export default Logo;
