import React from "react";

const GameIcon = ({location}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M2.50825 15.8092C2.73114 12.3161 3.38737 9.7599 3.94345 8.27511C4.22419 7.5255 4.82818 6.96728 5.60145 6.78021C9.90147 5.73993 15.0986 5.73993 19.3986 6.78021C20.1719 6.96728 20.7759 7.5255 21.0566 8.27511C21.6127 9.7599 22.2689 12.3161 22.4918 15.8092C22.6251 17.8989 21.1148 19.0503 19.4429 19.8925C18.378 20.4289 17.5591 18.8457 17.0155 17.6203C16.7185 16.9508 16.0667 16.5356 15.3281 16.5356H9.67196C8.93331 16.5356 8.28158 16.9508 7.98456 17.6203C7.44089 18.8457 6.622 20.4289 5.55711 19.8925C3.90215 19.0588 2.37384 17.9157 2.50825 15.8092Z"
        stroke={`${location.pathname === '/games' ? "#FFA017" : "#FFF"}`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M5.5 4.5L7.46285 4M19.5 4.5L17.5 4"
        stroke={`${location.pathname === '/games' ? "#FFA017" : "#FFF"}`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M9.5 13L8 11.5M8 11.5L6.5 10M8 11.5L6.5 13M8 11.5L9.5 10"
        stroke={`${location.pathname === '/games' ? "#FFA017" : "#FFF"}`}
        stroke-width="1.5"
        stroke-linecap="round"
      />
      <path
        d="M16.4881 10H16.4971"
        stroke={`${location.pathname === '/games' ? "#FFA017" : "#FFF"}`}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M18.4881 13H18.4971"
        stroke={`${location.pathname === '/games' ? "#FFA017" : "#FFF"}`}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default GameIcon;
