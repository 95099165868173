import React from "react";

const HomeIcon = ({location}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M9.56165 4.82633L3.73911 9.92134C3.2398 10.3583 3.57458 11.1343 4.26238 11.1343C4.68259 11.1343 5.02324 11.4489 5.02324 11.8371V15.0806C5.02324 17.871 5.02324 19.2662 5.96176 20.1331C6.90029 21 8.41082 21 11.4319 21H13.5681C16.5892 21 18.0997 21 19.0382 20.1331C19.9768 19.2662 19.9768 17.871 19.9768 15.0806V11.8371C19.9768 11.4489 20.3174 11.1343 20.7376 11.1343C21.4254 11.1343 21.7602 10.3583 21.2609 9.92134L15.4383 4.82633C14.0469 3.60878 13.3512 3 12.5 3C11.6488 3 10.9531 3.60878 9.56165 4.82633Z"
        stroke={`${location.pathname === '/' ? "#FFA017" : "#FFF"}`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M12.5 16H12.509"
        stroke={`${location.pathname === '/' ? "#FFA017" : "#FFF"}`}
        stroke-width="2"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default HomeIcon;
