import React from "react";

const UsersGroupIcon = () => {
  return (
    <svg
      width="16"
      height="16"
      viewBox="0 0 16 16"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clip-path="url(#clip0_34_13843)">
        <path
          d="M13.8494 12C14.3489 12 14.7462 11.6856 15.103 11.2461C15.8333 10.3463 14.6342 9.62723 14.1769 9.27507C13.712 8.91708 13.1929 8.71428 12.6667 8.66667M12 7.33333C12.9205 7.33333 13.6667 6.58714 13.6667 5.66667C13.6667 4.74619 12.9205 4 12 4"
          stroke="#1E1E1E"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M2.15048 12C1.65094 12 1.25362 11.6856 0.896868 11.2461C0.166563 10.3463 1.36562 9.62723 1.82293 9.27507C2.28782 8.91708 2.80689 8.71428 3.33317 8.66667M3.6665 7.33333C2.74603 7.33333 1.99984 6.58714 1.99984 5.66667C1.99984 4.74619 2.74603 4 3.6665 4"
          stroke="#1E1E1E"
          stroke-width="1.5"
          stroke-linecap="round"
        />
        <path
          d="M5.38904 10.0741C4.70785 10.4953 2.92183 11.3554 4.00964 12.4316C4.54102 12.9573 5.13285 13.3333 5.87692 13.3333H10.1228C10.8668 13.3333 11.4587 12.9573 11.99 12.4316C13.0778 11.3554 11.2918 10.4953 10.6106 10.0741C9.01327 9.0864 6.98641 9.0864 5.38904 10.0741Z"
          stroke="#1E1E1E"
          stroke-width="1.5"
          stroke-linecap="round"
          stroke-linejoin="round"
        />
        <path
          d="M10.3332 5C10.3332 6.28867 9.2885 7.33333 7.99984 7.33333C6.71117 7.33333 5.6665 6.28867 5.6665 5C5.6665 3.71134 6.71117 2.66667 7.99984 2.66667C9.2885 2.66667 10.3332 3.71134 10.3332 5Z"
          stroke="#1E1E1E"
          stroke-width="1.5"
        />
      </g>
      <defs>
        <clipPath id="clip0_34_13843">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
};

export default UsersGroupIcon;
