import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import LeadersCard from "../components/LeadersCard";
import { userContext } from "../context/userContext";
import { httpReauest } from "../utils/httpRequest";

const LeadersPage = () => {
  const { data, setData, balance, setBalance, id, setId } =
    useContext(userContext);
  const [users, setUsers] = useState([]);
  async function getusers() {
    const response = await httpReauest("GET", "/user", {}, {}).then((e) => {
      if (e.status === 200) {
        console.log(e);
        const sortedItems = e?.data?.data.sort(
          (a, b) => b?.balance - a?.balance
        );

        setUsers(sortedItems);
      }
    });
  }

  useEffect(() => {
    getusers();
  }, []);
  return (
    <div className="flex flex-col items-center px-4">
      <h2 className="text-[36px] my-5 mt-8">Leaderboard</h2>
      <img
        className="w-[71px] h-[71px]   rounded-full"
        src="/img/avatar2.png"
        alt=""
      />
      <span className="text-center text-[16px] font-semibold mt-4">
        {data?.user?.username}
      </span>
      <span className="mt-7 text-[24px] flex mb-5 items-center gap-2 bg-[#1E1E1E] py-2 px-4 rounded-[16px] text-[#F4AA3F] border-[#F4AA3F]/20 border-[1px]">
        <img src="/img/jack.png" alt="" />
        <span>{balance} $pump</span>
      </span>
      <div className="h-[400px] overflow-y-auto  w-full">
        {users?.map((e, i) => (
          <LeadersCard name={e?.username} number={i} pump={e?.balance} />
        ))}
      </div>
      <div className="w-full bg-[#1E1E1E] flex mb-[100px] items-start flex-col mt-5 rounded-[16px] p-3 border-[1px] border-[#F4AA3F]/20">
        <p className="text-[14px] font-semibold">
          Your Rank #{data?.user?.rank_number}
        </p>
        <p className="text-[14px] text-[#FFF]/50">{data?.user?.rank} $Pumps</p>
      </div>
    </div>
  );
};

export default LeadersPage;
