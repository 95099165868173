import React, { useContext } from "react";
import { useState } from "react";
import TaskCard from "../components/TaskCard";
import { userContext } from "../context/userContext";

const TasksPage = () => {
  const [active, setActive] = useState("pump");
  const { data, setData, balance, setBalance, id, SetId } =
    useContext(userContext);
  const tasks = data?.tasks;
  console.log(tasks);
  return (
    <div className="flex flex-col items-center px-4">
      <h2 className="text-[36px] my-5 mt-8 flex ">
        Pump Tasks{" "}
        <img
          className="w-[46px] object-cover h-[44px]"
          src="/img/rocket.png"
          alt=""
        />
      </h2>
      <p className="text-[#FFF]/50 text-[16px] text-center mb-5">
        Wanna earn Pumpkins and get ready for Pump?
      </p>
      <div className="grid grid-cols-3 gap-1 w-full mb-5 ">
        <span
          onClick={() => setActive("pump")}
          className={`border border-[#FFF]/50 py-1 rounded-[16px] duration-200 text-center w-full ${
            active === "pump"
              ? "bg-[#FFA017] text-[#000] text-[16px]"
              : "text-[14px] bg-none text-[#FFF]"
          }`}
        >
          Daily
        </span>
        <span
          onClick={() => setActive("partner")}
          className={`border border-[#FFF]/50 py-1 rounded-[16px] duration-200 text-center w-full ${
            active === "partner"
              ? "bg-[#FFA017] text-[#000] text-[16px]"
              : "text-[14px] bg-none text-[#FFF]"
          }`}
        >
          Partner
        </span>
        <span
          onClick={() => setActive("ambassador")}
          className={`border border-[#FFF]/50 py-1 rounded-[16px] duration-200 text-center w-full ${
            active === "ambassador"
              ? "bg-[#FFA017] text-[#000] text-[16px]"
              : "text-[14px] bg-none text-[#FFF]"
          }`}
        >
          Ambassador
        </span>
      </div>
      {active === "pump" &&
        tasks?.map((e) => (
          <TaskCard
            data={e}
            user={data}
            setData={setData}
            setBalance={setBalance}
            id={id}
          />
        ))}
    </div>
  );
};

export default TasksPage;
