import React, { useContext } from "react";
import { useEffect } from "react";
import { useState } from "react";
import { useLocation, useSearchParams } from "react-router-dom";
import Loading from "../components/Loading";
import RankIcon from "../icons/RankIcon";
import { httpReauest } from "../utils/httpRequest";
import { userContext } from "../context/userContext";
import Load from "../components/Load";
import Logo from "../components/Logo";
import a from "../animatioms/motionlottie.json";
import Lottie from "lottie-react";
const Home = ({ start, setStart, setLoading, loading }) => {
  const [searchParams] = useSearchParams();

  const { data, setData, balance, setBalance, id, setId } =
    useContext(userContext);

  async function getUser() {
    setLoading(true);
    if (id === "") {
      const user = searchParams.get("telegramId");
      const response = await httpReauest("GET", `/user/${user}`, {}, {}).then(
        (e) => {
          setId(user);
          console.log(e);
          setStart(e?.data?.data?.user?.first);
          setData(e?.data?.data);

          setBalance(e?.data?.data?.user?.balance);
        }
      );
    } else {
      const response = await httpReauest("GET", `/user/${id}`, {}, {}).then(
        (e) => {
          console.log(e);
          setStart(e?.data?.data?.user?.first);
          setData(e?.data?.data);

          setBalance(e?.data?.data?.user?.balance);
        }
      );
    }
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }
  useEffect(() => {
    getUser();
  }, []);

  if (loading) {
    return (
     <div className="w-full flex justify-center h-full relative">
      <img className="w-full h-[100vh] object-cover" src="/loading.png" alt="" />
      <img className="absolute  top-[52%] object-cover" src="/pumpkin.png" alt="" />
     </div>
    );
  }
  return (
    <div className="flex flex-col items-center px-4 pb-[60px]">
      {!start ? (
        <>
          <span style={{pointerEvents:'none'}} className="w-full flex relative bg-[#FFA017] rounded-[16px] text-[#000000] px-6 mt-5 py-5 font-semibold text-[14px] ">
            <p>Hi , {data?.user?.username}</p>
            <img
              className="absolute bottom-[-45%] right-10 border-[6px] border-[#010800] rounded-full w-[56px]"
              src="/img/Avatar.png"
              alt=""
            />
          </span>
          <span style={{pointerEvents:'none'}} className="mt-7 text-[24px] bg-[#1E1E1E] py-2 px-4 rounded-[16px] text-[#F4AA3F] border-[#F4AA3F]/20 border-[1px]">
            <span>{balance} $pump</span>
          </span>
          <div style={{pointerEvents:'none'}} className="grid grid-cols-3 justify-items-center gap-2 mt-5">
            <span className="felx w-full flex-col items-center text-center gap-2  border-[1px] border-[#F4AA3F]/20 p-2 rounded-[16px] justify-center text-[14px]">
              <p className="text-[#FFF]/50">Rewards</p>
              <span className="flex w-full justify-center items-center gap-1">
                +200 <img src="/img/minilogo.png" alt="" />
              </span>
            </span>
            <span className="felx w-full flex-col items-center gap-2 text-center  border-[1px] border-[#F4AA3F]/20 p-2 rounded-[16px] justify-center text-[14px]">
              <p className="text-[#FFF]/50">Tasks</p>
              <span className="flex w-full justify-center items-center gap-1">
                {data?.tasks?.length} <img src="/img/minilogo.png" alt="" />
              </span>
            </span>
            <span className="felx w-full  flex-col items-center gap-2 border-[1px] border-[#F4AA3F]/20 p-2 rounded-[16px] justify-center text-[14px]">
              <p className="text-[#FFF]/50 text-center">Invites</p>
              <span className="flex w-full justify-center items-center gap-1">
                {data?.invitedPepole?.length}{" "}
                <img src="/img/minilogo.png" alt="" />
              </span>
            </span>
          </div>
          <Logo />
          <div style={{pointerEvents:'none'}} className="w-full bg-[#1E1E1E] flex items-start flex-col mt-5 rounded-[16px] p-3 border-[1px] border-[#F4AA3F]/20">
            <p className="text-[14px] font-semibold">
              Your Rank #{data?.user?.rank_number}
            </p>
            <p className="text-[14px] text-[#FFF]/50">
              {data?.user?.rank} $Pumps
            </p>
          </div>

          <div style={{pointerEvents:'none'}} className="w-full bg-[#1E1E1E] flex items-start flex-col mt-1 rounded-[16px] p-3 border-[1px] border-[#F4AA3F]/20">
            <p className="text-[14px] font-semibold">Telegram Premium</p>
            <p className="text-[14px] text-[#FFF]/50">
              {data?.user?.premium
                ? "3.000 $Pump"
                : "Upgrade your account and take your 3.000 $Pumps"}
            </p>
          </div>
        </>
      ) : (
        <Loading setStart={setStart} user={data?.user} balance={balance} />
      )}
    </div>
  );
};

export default Home;
