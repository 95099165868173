import React from "react";

const StartupIcon = ({location}) => {
  return (
    <svg
      width="25"
      height="24"
      viewBox="0 0 25 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.79469 17C4.03045 7.25 9.36313 2.9375 12.5 2C15.6369 2.9375 20.9696 7.25 17.2053 17C16.6369 16.6875 14.9 16.0625 12.5 16.0625C10.1 16.0625 8.36313 16.6875 7.79469 17Z"
        stroke={`${location.pathname === '/tasks' ? "#FFA017" : "#FFF"}`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M14.5 9C14.5 7.89543 13.6046 7 12.5 7C11.3954 7 10.5 7.89543 10.5 9C10.5 10.1046 11.3954 11 12.5 11C13.6046 11 14.5 10.1046 14.5 9Z"
        stroke={`${location.pathname === '/tasks' ? "#FFA017" : "#FFF"}`}
        stroke-width="1.5"
      />
      <path
        d="M18 15.5576C19.4421 15.6908 21.2078 16.0822 22.4814 17C22.4814 17 23.0044 12.0642 18.5 11"
        stroke={`${location.pathname === '/tasks' ? "#FFA017" : "#FFF"}`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M7 15.5576C5.55794 15.6908 3.79216 16.0822 2.51858 17C2.51858 17 1.99555 12.0642 6.5 11"
        stroke={`${location.pathname === '/tasks' ? "#FFA017" : "#FFF"}`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
      <path
        d="M10 19C10 19 10.4167 21.5 12.5 22C14.5833 21.5 15 19 15 19"
        stroke={`${location.pathname === '/tasks' ? "#FFA017" : "#FFF"}`}
        stroke-width="1.5"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </svg>
  );
};

export default StartupIcon;
