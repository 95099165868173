import { useEffect } from "react";
import { useState } from "react";
import {
  BrowserRouter,
  Route,
  Routes,
  useSearchParams,
} from "react-router-dom";
import "./app.css";

import Home from "./pages/Home";

import { userContext } from "./context/userContext";
import Navbar from "./components/Navbar";
import Burgers from "./components/Burgers";
import GamePage from "./pages/GamePage";
import TasksPage from "./pages/TasksPage";
import LeadersPage from "./pages/LeadersPage";
import FriendsPage from "./pages/FriendsPage";
import Load from "./components/Load";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const App = () => {
  const [data, setData] = useState([]);
  const [start, setStart] = useState(false);
  const [balance, setBalance] = useState(0);
  const [id, setId] = useState("");
  const [loading, setLoading] = useState(true);
  
  return (
    <>
      <BrowserRouter>
      <ToastContainer/>
        <userContext.Provider value={{ data, setData, balance, setBalance,id ,setId }}>
          {/* <Navbar /> */}
          <Routes>
            <Route
              path="/"
              element={<Home start={start} setStart={setStart} setLoading={setLoading} loading={loading} />}
            />
            <Route path="/games" element={<GamePage />} />
            <Route path="/tasks" element={<TasksPage />} />
            <Route path="/leaders" element={<LeadersPage />} />
            <Route path="/friends" element={<FriendsPage />} />
          </Routes>
          {!start && !loading && <Burgers />}
        </userContext.Provider>
      </BrowserRouter>
    </>
  );
};

export default App;
