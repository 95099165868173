import React from "react";

const GameCard = ({ name, img }) => {
  return (
    <div className="grid  grid-cols-3 w-full bg-[#1E1E1E] rounded-[16px] p-2 gap-4 mt-1">
      <img className="col-span-1" src={img} alt="" />
      <span className="col-span-2 flex flex-col gap-3">
        <p className="font-bold text-[16px] mt-3">{name}</p>
        <button className="bg-[#FFA017] w-fit px-6 py-1 font-semibold text-[16px] text-[#000] rounded-[16px]">
            Coming soon
        </button>
      </span>
    </div>
  );
};

export default GameCard;
