import React, { useContext } from "react";
import { Link } from "react-router-dom";
import { userContext } from "../context/userContext";
import CircleArrowIcon from "../icons/CircleArrowIcon";
import UsersGroupIcon from "../icons/UsersGroupIcon";

const FriendsPage = () => {
  const { data, setData, balance, setBalance, id, SetId } =
    useContext(userContext);
  const textToCopy = `https://t.me/PumpkinsPumpBot?start=test`;
  const handleCopy = () => {
    navigator.clipboard.writeText(textToCopy).then(() => {});
  };
  return (
    <div className="flex flex-col items-center px-4">
      <h2 className="text-[20px] w-full mt-5">
        Invite your friends & earn $Pump
      </h2>
      <img className="w-full mt-5" src="/img/Pic.png" alt="" />
      <h2 className="text-[24px] w-full mt-5">How it works</h2>
      <div className="flex justify-start items-start w-full mt-6 gap-3 text-[16px]">
        <CircleArrowIcon />
        <span className="flex flex-col items-start">
          <p>Invite your friends</p>
          <p className="text-[#F4AA3F]/50">Share the link with them</p>
        </span>
      </div>
      <div className="flex justify-start items-start w-full mt-4 gap-3 text-[16px]">
        <CircleArrowIcon />
        <span className="flex flex-col items-start">
          <p>Ask your friends to join pumps</p>
          <p className="text-[#F4AA3F]/50">Both of you earn poins</p>
        </span>
      </div>
      <div className="grid grid-cols-2 gap-4 w-full mt-10">
        <Link className="w-full flex items-center justify-center rounded-[16px] py-3 bg-[#F4AA3F] text-[#000]">
          <UsersGroupIcon /> Invite Friend
        </Link>
        <button
          onClick={handleCopy}
          className="w-full text-center rounded-[16px] py-3 bg-[#1E1E1E] border-[1px] border-[#F4AA3F]/20 text-[#FFF]  text-[#000]"
        >
          Copy Referral Link
        </button>
      </div>
    </div>
  );
};

export default FriendsPage;
