import React from "react";
import { Link, useLocation } from "react-router-dom";
import CrownIcon from "../icons/CrownIcon";
import FriendsIcon from "../icons/FriendsIcon";
import GameIcon from "../icons/GameIcon";
import HomeIcon from "../icons/HomeIcon";
import StartupIcon from "../icons/StartupIcon";

const Burgers = () => {
  const location = useLocation();
  return (
    <div className="grid grid-cols-5 fixed bottom-0 left-0 right-0 py-3 px-4 bg-[#181818]">
      <Link to={"/"} className="text-[14px] flex flex-col items-center">
        <HomeIcon location={location} />
        <p className={`${location.pathname === '/' ? "text-[#FFA017]" : "text-[#FFF]"}`}>Home</p>
      </Link>
      <Link to={"/games"} className="text-[14px] flex flex-col items-center">
        <GameIcon location={location}/>
        <p className={`${location.pathname === '/games' ? "text-[#FFA017]" : "text-[#FFF]"}`}>Games</p>
      </Link>
      <Link to={"/tasks"} className="text-[14px] flex flex-col items-center">
        <StartupIcon location={location}/>
        <p className={`${location.pathname === '/tasks' ? "text-[#FFA017]" : "text-[#FFF]"}`}>Tasks</p>
      </Link>
      <Link to={"/leaders"} className="text-[14px] flex flex-col items-center">
        <CrownIcon location={location}/>
        <p className={`${location.pathname === '/leaders' ? "text-[#FFA017]" : "text-[#FFF]"}`}>Leaders</p>
      </Link>
      <Link to={'/friends'} className="text-[14px] flex flex-col items-center">
        <FriendsIcon location={location}/>
        <p className={`${location.pathname === '/friends' ? "text-[#FFA017]" : "text-[#FFF]"}`}>Friends</p>
      </Link>
    </div>
  );
};

export default Burgers;
