import React from "react";
import { useState } from "react";
import RankIcon from "../icons/RankIcon";

const Loading = ({ setStart, user,balance }) => {
  const [step, setStep] = useState(false);
  return (
    <div className="flex flex-col items-center">
      {step ? (
        <>
          <span className="flex items-center gap-1 mt-10">
            <span className="text-[14px] mt-1">
              {user?.premium ? "Your Premium !" : "Not Premium yet !"}
            </span>
          </span>

          <p className="text-[#FFFFFF]/50 text-[14px] text-center mx-10 mt-3">
            {user?.premium
              ? "Your account is premium and you get 3,000 $Pump"
              : "Upgrade your account and take your 3,000 $Pump"}
          </p>
        </>
      ) : (
        <>
          <span className="flex items-center gap-1 mt-10">
            <RankIcon /> <span className="text-[24px]">{user?.rank}</span>{" "}
            <span className="text-[14px] mt-1">$Pump</span>
          </span>

          <p className="text-[#FFFFFF]/50 text-[14px]">For your Rank #{user?.rank_number}  </p>
        </>
      )}

      <img
        className="mt-10 img-shadow w-[220px] "
        src="/img/logo2.png"
        alt=""
      />
      <h2 className="text-[36px] text-center font-bold mt-10">Pumpkins</h2>
      <button
        onClick={() => {
          if (step) {
            setStart(false);
          } else setStep(true);
        }}
        className="bg-[#FFA017] text-[#000] px-7 py-2 rounded-[16px] text-[16px] mt-10 font-[500]"
      >
        {step ? "Continue" : "Get Start"}
      </button>
    </div>
  );
};

export default Loading;
